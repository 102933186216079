import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { CssBaseline, Grid, List, ListItem, ListItemText } from '@material-ui/core';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';

import Appbar from './Appbar';

import { setUser } from '../store/action';

import { API } from '../config/API';

function Navsidebar(props) {
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	function handleListItemClick(event, index) {
		setSelectedIndex(index);
	}

	useEffect(() => {
		if (localStorage.getItem('MEGAFIT_TKN')) {
			API.get('/users/check-token', { headers: { token: localStorage.getItem('MEGAFIT_TKN') } })
				.then(({ data }) => {
					if (data.roleId === 2) {
						props.history.push('/home');
					} else if (data.roleId === 6) {
						props.history.push('/pt');
					} else if (data.roleId === 7) {
						props.history.push('/setting-class');
					} else {
						props.history.push('/checkin');
					}
					props.setUser({
						userId: data.userId,
						roleId: data.roleId,
						fullname: data.fullname,
						nickname: data.nickname,
						hasConfirmTermAndCondition: data.hasConfirmTermAndCondition,
						hasSeenSdkFreeze: data.hasSeenSdkFreeze,
						isFreeze: data.isFreeze,
						freezeDate: data.freezeDate,
						memberId: data.memberId,
						canPTOnline: data.canPTOnline,
						staffId: data.staffId,
						dataMemberClasses: data.memberClasses
					});
				})
				.catch((err) => {
					props.history.push('/');
				});
		} else {
			if (!props.location.pathname.match(/reset-password/i)) {
				props.history.push('/');
			}
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (props.location.pathname === '/home') {
			setSelectedIndex(1);
		} else if (props.location.pathname === '/kelas') {
			setSelectedIndex(2);
		} else if (props.location.pathname === '/checkin') {
			setSelectedIndex(3);
		} else if (props.location.pathname === '/anggota') {
			setSelectedIndex(4);
		} else if (props.location.pathname === '/gym') {
			setSelectedIndex(5);
		} else if (props.location.pathname === '/pos') {
			setSelectedIndex(6);
		} else if (props.location.pathname === '/pt') {
			setSelectedIndex(7);
		} else if (props.location.pathname === '/staff') {
			setSelectedIndex(8);
		} else if (props.location.pathname === '/report') {
			setSelectedIndex(9);
		} else if (props.location.pathname === '/client') {
			setSelectedIndex(10);
		} else if (props.location.pathname === '/target') {
			setSelectedIndex(11);
		} else if (props.location.pathname === '/setting-class') {
			setSelectedIndex(12);
		}
	}, [props.location.pathname]);

	useEffect(() => {
		if (props.roleId === 2) {
			props.history.push('/home');
		} else if (props.roleId === 6) {
			props.history.push('/pt');
		} else if (props.roleId === 7) {
			props.history.push('/setting-class');
		}
	}, [props.roleId, props.history]);

	function navigateFromLogo() {
		if (props.roleId === 2) {
			props.history.push('/home');
		} else if (props.roleId === 6) {
			props.history.push('/pt');
		} else if (props.roleId === 7) {
			props.history.push('/setting-class');
		} else {
			props.history.push('/checkin');
		}
	}

	if (isMobile) return null;

	return (
		<Grid style={{ display: 'flex' }}>
			{props.userId && (
				<>
					<CssBaseline />
					<Appbar />
					<Grid
						variant="permanent"
						style={{
							zIndex: 99,
							position: 'fixed',
							display: 'flex',
							flexDirection: 'column',
							width: props.roleId === 2 ? 200 : 100,
							height: '100%',
							backgroundColor: '#8EB52F',
							overflowY: 'auto'
						}}
						open={false}
					>
						{props.roleId === 2 ? ( // FOR MEMBER
							<>
								<img
									src={require('../asset/logo-megafit.png')}
									style={{ alignSelf: 'center', marginTop: 20, marginBottom: 25, cursor: 'pointer' }}
									height={20}
									width={170}
									alt="logo-megafit"
									onClick={navigateFromLogo}
								/>
								<List component="nav" style={{ color: 'white', marginLeft: 30 }}>
									<Link
										to="/home"
										onClick={(event) => handleListItemClick(event, 1)}
										style={{ textDecoration: 'none' }}
									>
										<ListItem
											button
											selected={selectedIndex === 1}
											style={{
												display: 'flex',
												justifyContent: 'center',
												borderTopLeftRadius: 50,
												borderBottomLeftRadius: 50,
												paddingLeft: 30,
												marginBottom: 5
											}}
										>
											<HomeOutlinedIcon style={{ fontSize: 30, color: 'white', marginRight: 10 }} />
											<ListItemText primary="Beranda" style={{ fontSize: 20, color: 'white' }} />
										</ListItem>
									</Link>
									<Link
										to="/target"
										onClick={(event) => handleListItemClick(event, 11)}
										style={{ textDecoration: 'none' }}
									>
										<ListItem
											button
											selected={selectedIndex === 11}
											style={{
												display: 'flex',
												justifyContent: 'center',
												borderTopLeftRadius: 50,
												borderBottomLeftRadius: 50,
												paddingLeft: 30,
												marginBottom: 5
											}}
										>
											<TrackChangesIcon style={{ fontSize: 30, color: 'white', marginRight: 10 }} />
											<ListItemText primary="Target" style={{ fontSize: 20, color: 'white' }} />
										</ListItem>
									</Link>
									<Link
										to="/kelas"
										onClick={(event) => handleListItemClick(event, 2)}
										style={{ textDecoration: 'none' }}
									>
										<ListItem
											button
											selected={selectedIndex === 2}
											style={{
												display: 'flex',
												justifyContent: 'center',
												borderTopLeftRadius: 50,
												borderBottomLeftRadius: 50,
												paddingLeft: 30,
												marginBottom: 5
											}}
										>
											<EventNoteOutlinedIcon style={{ fontSize: 30, color: 'white', marginRight: 10 }} />
											<ListItemText primary="Kelas" style={{ fontSize: 20, color: 'white' }} />
										</ListItem>
									</Link>
								</List>
							</>
						) : (
							<>
								<img
									src={require('../asset/logo-megafit-2.png')}
									style={{ alignSelf: 'center', marginTop: 20, marginBottom: 30, cursor: 'pointer' }}
									height={80}
									width={80}
									alt="logo-megafit"
									onClick={navigateFromLogo}
								/>
								<List component="nav" style={{ color: 'white' }}>
									{props.roleId === 6 ? ( //FOR PT
										<>
											<Link
												to="/pt"
												onClick={(event) => handleListItemClick(event, 7)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 7}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<img
														src={require('../asset/jadwal.png')}
														style={{ alignSelf: 'center' }}
														height={70}
														width={45}
														alt="logo-pt"
													/>
												</ListItem>
											</Link>
											<Link
												to="/client"
												onClick={(event) => handleListItemClick(event, 10)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 10}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<img
														src={require('../asset/client.png')}
														style={{ alignSelf: 'center' }}
														height={70}
														width={45}
														alt="logo-pt"
													/>
												</ListItem>
											</Link>
										</>
									) : props.roleId === 7 ? (
										<Link
											to="/setting-class"
											onClick={(event) => handleListItemClick(event, 12)}
											style={{ textDecoration: 'none' }}
										>
											<ListItem
												button
												selected={selectedIndex === 12}
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													marginBottom: 5
												}}
											>
												<EventNoteOutlinedIcon style={{ alignSelf: 'center', color: 'white', height: 40, width: 40 }} />
												<b style={{ margin: 0, color: 'white', textDecoration: 'none', fontSize: 14 }}>kelas</b>
											</ListItem>
										</Link>
									) : (
										<>
											<Link
												to="/checkin"
												onClick={(event) => handleListItemClick(event, 3)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 3}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<img
														src={require('../asset/checkin.png')}
														style={{ alignSelf: 'center' }}
														height={60}
														width={50}
														alt="logo-checkin"
													/>
												</ListItem>
											</Link>

											<Link
												to="/setting-class"
												onClick={(event) => handleListItemClick(event, 12)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 12}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<EventNoteOutlinedIcon
														style={{ alignSelf: 'center', color: 'white', height: 40, width: 40 }}
													/>
													<b style={{ margin: 0, color: 'white', textDecoration: 'none', fontSize: 14 }}>kelas</b>
												</ListItem>
											</Link>

											<Link
												to="/anggota"
												onClick={(event) => handleListItemClick(event, 4)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 4}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<img
														src={require('../asset/member.png')}
														style={{ alignSelf: 'center' }}
														height={60}
														width={50}
														alt="logo-anggota"
													/>
												</ListItem>
											</Link>
											{props.roleId !== 5 && (
												<Link
													to="/staff"
													onClick={(event) => handleListItemClick(event, 8)}
													style={{ textDecoration: 'none' }}
												>
													<ListItem
														button
														selected={selectedIndex === 8}
														style={{
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'center',
															marginBottom: 5
														}}
													>
														<img
															src={require('../asset/staff.png')}
															style={{ alignSelf: 'center' }}
															height={60}
															width={50}
															alt="logo-staff"
														/>
													</ListItem>
												</Link>
											)}
											{props.roleId !== 5 && (
												<Link
													to="/gym"
													onClick={(event) => handleListItemClick(event, 5)}
													style={{ textDecoration: 'none' }}
												>
													<ListItem
														button
														selected={selectedIndex === 5}
														style={{
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'center',
															marginBottom: 5
														}}
													>
														<img
															src={require('../asset/gym.png')}
															style={{ alignSelf: 'center' }}
															height={70}
															width={45}
															alt="logo-gym"
														/>
													</ListItem>
												</Link>
											)}
											<Link
												to="/report"
												onClick={(event) => handleListItemClick(event, 9)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 9}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<img
														src={require('../asset/laporan.png')}
														style={{ alignSelf: 'center' }}
														height={60}
														width={50}
														alt="logo-laporan"
													/>
												</ListItem>
											</Link>
											<Link
												to="/pos"
												onClick={(event) => handleListItemClick(event, 6)}
												style={{ textDecoration: 'none' }}
											>
												<ListItem
													button
													selected={selectedIndex === 6}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 5
													}}
												>
													<img
														src={require('../asset/POS.png')}
														style={{ alignSelf: 'center' }}
														height={70}
														width={45}
														alt="logo-pos"
													/>
												</ListItem>
											</Link>
										</>
									)}
								</List>
							</>
						)}
					</Grid>
				</>
			)}
		</Grid>
	);
}

const mapDispatchToProps = {
	setUser
};

const mapStateToProps = ({ roleId, userId, nickname }) => {
	return {
		roleId,
		userId,
		nickname
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navsidebar));
