const defaultState = {
	userId: null,
	memberId: null,
	staffId: null,
	roleId: null,
	fullname: '',
	nickname: '',
	hasConfirmTermAndCondition: false,
	hasSeenSdkFreeze: null,
	isFreeze: null,
	freezeDate: null,
	loading: false,
	lockerKey: null,
	dataMemberClasses: [],
	dataSubCategoryMemberships: [],
	dataCategoryMemberships: [],
	dataPackageMemberships: [],
	dataUserDetail: null,
	dataClassPt: [],
	dataMyJoinedClassPt: [],
	dataClassMegafit: [],
	dataAllStaff: [],
	dataTransaction: [],
	allTransaction: [],
	isReservation: null,
	reservationDate: null,
	reservationTime: null,
	checkinTime: null,
	checkoutTime: null,
	canPTOnline: false
};

function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'SET_USER': {
			return {
				...state,
				userId: action.payload.userId,
				roleId: action.payload.roleId,
				fullname: action.payload.fullname,
				nickname: action.payload.nickname,
				hasConfirmTermAndCondition: action.payload.hasConfirmTermAndCondition,
				hasSeenSdkFreeze: action.payload.hasSeenSdkFreeze,
				isFreeze: action.payload.isFreeze,
				freezeDate: action.payload.freezeDate,
				memberId: action.payload.memberId,
				canPTOnline: action.payload.canPTOnline,
				staffId: action.payload.staffId,
				dataMemberClasses: action.payload.dataMemberClasses
			};
		}
		case 'SEEN_FREEZE': {
			return {
				...state,
				hasSeenSdkFreeze: action.payload.hasSeenSdkFreeze
			};
		}
		case 'SET_FREEZE': {
			return {
				...state,
				isFreeze: action.payload.isFreeze,
				freezeDate: action.payload.freezeDate
			};
		}
		case 'SET_RESERVATION': {
			return {
				...state,
				isReservation: action.payload.isReservation,
				reservationDate: action.payload.reservationDate,
				reservationTime: action.payload.reservationTime,
				checkinTime: action.payload.checkinTime,
				checkoutTIme: action.payload.checkoutTime
			};
		}
		case 'FETCH_DATA_USER_DETAIL_SUCCESS': {
			return {
				...state,
				loading: false,
				dataUserDetail: action.payload.dataUserDetail,
				lockerKey: action.payload.lockerKey
			};
		}
		case 'FETCH_TRANSACTION_SUCCESS': {
			return {
				...state,
				dataTransaction: action.payload.dataTransaction
			};
		}
		case 'FETCH_DATA_TRANSACTION_SUCCESS': {
			return {
				...state,
				allTransaction: action.payload.allTransaction
			};
		}
		case 'FETCH_DATA_SUB_CATEGORY_MEMBERSHIPS_SUCCESS': {
			return {
				...state,
				loading: false,
				dataSubCategoryMemberships: action.payload.dataSubCategoryMemberships
			};
		}
		case 'FETCH_DATA_CATEGORY_MEMBERSHIPS_SUCCESS': {
			return {
				...state,
				loading: false,
				dataCategoryMemberships: action.payload.dataCategoryMemberships
			};
		}
		case 'FETCH_DATA_PACKAGE_MEMBERSHIPS_SUCCESS': {
			return {
				...state,
				loading: false,
				dataPackageMemberships: action.payload.dataPackageMemberships
			};
		}
		case 'FETCH_DATA_STAFF_SUCCESS': {
			return {
				...state,
				loading: false,
				dataAllStaff: action.payload.dataStaff
			};
		}
		case 'FETCH_DATA_MEMBER_SUCCESS': {
			return {
				...state,
				loading: false,
				dataAllMember: action.payload.dataMember
			};
		}
		case 'FETCH_DATA_CLASS_PT_SUCCESS': {
			return {
				...state,
				loading: false,
				dataClassPt: action.payload.dataClassPt
			};
		}
		case 'FETCH_DATA_MY_JOINED_CLASS_PT_SUCCESS': {
			return {
				...state,
				loading: false,
				dataMyJoinedClassPt: action.payload.dataMyJoinedClassPt
			};
		}
		case 'FETCH_DATA_CLASS_MEGAFIT_SUCCESS': {
			return {
				...state,
				loading: false,
				dataClassMegafit: action.payload.dataClassMegafit
			};
		}
		case 'FETCH_DATA_MEMBER_CLASSES_SUCCESS': {
			return {
				...state,
				loading: false,
				dataMemberClasses: action.payload.dataMemberClasses
			};
		}
		case 'RESET_CLASS_PT': {
			return {
				...state,
				dataClassPt: []
			};
		}
		case 'RESET_CLASS_MEGAFIT': {
			return {
				...state,
				dataClassMegafit: []
			};
		}
		case 'FETCH_DATA_LOADING': {
			return {
				...state,
				loading: true
			};
		}
		case 'FETCH_DATA_ERROR': {
			return {
				...state,
				loading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
}

export default reducer;
