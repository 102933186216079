import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Loading from './components/Loading';

const Login = lazy(() => import('./views/Login'));
const Home = lazy(() => import('./views/Home'));
const Paket = lazy(() => import('./views/Paket'));
const Profil = lazy(() => import('./views/Profil'));
const Kelas = lazy(() => import('./views/Kelas'));
const Checkin = lazy(() => import('./views/Checkin'));
const Anggota = lazy(() => import('./views/Anggota'));
const Gym = lazy(() => import('./views/Gym/Gym'));
const AddProduct = lazy(() => import('./views/Gym/AddProduct'));
const POS = lazy(() => import('./views/POS'));
const PT = lazy(() => import('./views/PT/PT'));
const DetailUserPT = lazy(() => import('./views/PT/DetailUserJadwalPT'));
const Staff = lazy(() => import('./views/Staff'));
const Laporan = lazy(() => import('./views/Laporan'));
const Client = lazy(() => import('./views/PT/Client'));
const Target = lazy(() => import('./views/Target'));
const ForgetPassword = lazy(() => import('./views/ForgetPassword'));
const ResetPassword = lazy(() => import('./views/ResetPassword'));
const Index = lazy(() => import('./views/Index'));
const InformasiCorona = lazy(() => import('./views/InformasiCorona'));
const JoinMegafit = lazy(() => import('./views/JoinMegafit'));
const Pendaftaran = lazy(() => import('./views/Pendaftaran'));
const SettingClass = lazy(() => import('./views/SettingClass/ClassMegafit'));

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar
	}
}));

function Routes(props) {
	const classes = useStyles();

	return (
		<main
			style={{
				flexGrow: 1,
				marginLeft:
					!localStorage.getItem('MEGAFIT_TKN') || isMobile ? 0 : props.roleId ? (props.roleId === 2 ? 200 : 100) : 0
			}}
		>
			{localStorage.getItem('MEGAFIT_TKN') && props.roleId && !isMobile && <div className={classes.toolbar} />}
			<Suspense fallback={<Loading loading={true} />}>
				<Switch>
					<Route exact path="/" component={Index} />
					<Route exact path="/login" component={Login} />
					<Route path="/join-megafit" component={JoinMegafit} />
					<Route path="/forget-password" component={ForgetPassword} />
					<Route path="/reset-password/:token" component={ResetPassword} />
					<Route path="/informasi-corona" component={InformasiCorona} />
					<Route path="/pendaftaran" component={Pendaftaran} />
					<AuthenticatedRoute path="/home/paket" component={Paket} />
					<AuthenticatedRoute path="/home/paketPT" component={Paket} />
					<AuthenticatedRoute path="/home/paketOnline" component={Paket} />
					<AuthenticatedRoute path="/home/paketClass" component={Paket} />
					<AuthenticatedRoute path="/home" component={Home} />
					<AuthenticatedRoute path="/target" component={Target} />
					<AuthenticatedRoute path="/profil" component={Profil} />
					<AuthenticatedRoute path="/kelas" component={Kelas} />
					<AuthenticatedRoute path="/checkin" component={Checkin} />
					<AuthenticatedRoute path="/anggota" component={Anggota} />
					<AuthenticatedRoute path="/gym/add-product" component={AddProduct} />
					<AuthenticatedRoute path="/gym" component={Gym} />
					<AuthenticatedRoute path="/pos" component={POS} />
					<AuthenticatedRoute path="/pt/detail-user" component={DetailUserPT} />
					<AuthenticatedRoute path="/pt" component={PT} />
					<AuthenticatedRoute path="/client" component={Client} />
					<AuthenticatedRoute path="/staff" component={Staff} />
					<AuthenticatedRoute path="/report" component={Laporan} />
					<AuthenticatedRoute path="/setting-class" component={SettingClass} />
				</Switch>
			</Suspense>
		</main>
	);
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('MEGAFIT_TKN') ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/',
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);

const mapStateToProps = ({ roleId }) => {
	return {
		roleId
	};
};

export default connect(mapStateToProps)(withRouter(Routes));
