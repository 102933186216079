import axios from 'axios';

// const BaseURL = 'http://localhost:4000';
const BaseURL = 'https://api-megafit.xyz/'; //digitalocean

const API = axios.create({
  baseURL: BaseURL,
});

export {API, BaseURL};
